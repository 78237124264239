@use '~/src/lib/mixins';

.PlayerHighlight {
	display: grid;
	grid-template-columns: auto 1fr;
	gap: 12px;
	padding: 12px 0;
	width: 100%;

	.Photos {
		display: grid;
		width: 88px;
		height: 88px;

		.Profile {
			display: flex;
			grid-area: 1/1;
			flex-direction: row;
			justify-content: center;
			border: 2px solid #ccc;
			border-radius: 50%;
			width: 80px;
			height: 80px;
			overflow: hidden;

			.Placeholder {
				width: 44px;
			}
		}

		.Team {
			display: flex;
			grid-area: 1/1;
			justify-content: center;
			align-items: center;
			align-self: flex-end;
			justify-self: flex-end;

			z-index: 1;
			border: 2px solid var(--color-brand-primary);
			border-radius: 50%;
			background-color: var(--color-brand-primary);
			width: 40px;
			height: 40px;
			overflow: hidden;

			.PlayerPosition {
				color: black;
				font-style: normal;
				font-weight: 400;
				font-size: 18px;
				line-height: 100%;
				font-family: 'Black Ops One';
				text-align: center;
			}
		}
	}

	.Info {
		.Name {
			color: var(--Lime-100, #c3fa02);
			font-style: normal;
			font-weight: 400;
			font-size: 24px;
			line-height: 120%; /* 28.8px */

			/* Display/Small */
			font-family: 'Black Ops One';
		}

		.Team {
			color: var(--Lime-20, #ecfeae);
			font-style: normal;
			font-weight: 600;
			font-size: 18px;
			line-height: 133%; /* 23.94px */

			/* Title/Small-SemiBold */
			font-family: 'Roboto Flex';
			text-decoration: none;
		}

		.Position {
			color: var(--Gray-30, #ccc);
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 150%; /* 24px */

			/* Body Copy/Small-Regular */
			font-family: 'Roboto Flex';
		}
	}
}
