@use '~/src/lib/mixins';

.IndexBanner {
	@include mixins.rounded;
	margin: 0 var(--size-gap-2x);
	color: var(--color-white);

	@include mixins.desktop {
		margin: 0;
	}

	img {
		border-bottom: 4px solid var(--color-brand-primary);
		aspect-ratio: 16/9;
		width: 100%;
		object-fit: cover;
	}

	.Text {
		padding: var(--size-gap-2x);

		a {
			display: flex;
			justify-content: center;
			align-items: center;
			transition: all 0.3s ease;
			border-radius: 24px;
			background: #c3fa02;
			max-width: 320px;
			height: 48px;
			color: #0f2d02;
			font-style: normal;
			font-weight: 600;
			font-size: 14px;
			line-height: 16px; /* 114.286% */

			/* Other/Button Text */
			font-family: 'Roboto Flex';
			text-align: center;
			text-decoration: none;
			text-transform: uppercase;

			&:hover {
				box-shadow: 0 0 5px 5px rgba(118, 229, 38, 0.3);
			}
		}
	}
}
