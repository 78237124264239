@use '~/src/lib/mixins';

.IndexVideos {
	@include mixins.desktop {
	}

	.Heading {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		margin-bottom: var(--size-gap-3x);
		padding: 0 var(--size-gap-2x);

		h2 {
			color: var(--color-white);
			font: var(--font-headline-m);
		}
		.More {
			display: block;
			color: var(--color-brand-primary-20);
			font: var(--font-body-s-r);
			svg {
				margin-left: var(--size-gap-2x);
				color: var(--color-brand-primary);
			}

			&.SoldOut {
				color: var(--color-gray);
				text-decoration: line-through;
			}
		}
	}
	.VideosList {
		display: flex;
		flex-direction: row;
		align-items: stretch;
		gap: var(--size-gap-2x);
		margin: 0;
		padding: 0 var(--size-gap-2x);
		max-width: var(--app-width);
		overflow-x: auto;
		overscroll-behavior-x: contain;
		scroll-behavior: smooth;

		.Video {
			display: flex;
			flex-shrink: 1;
			flex-direction: column;
			gap: var(--size-gap-quarter);
			background-color: var(--color-black);
			min-width: 250px;
			max-width: 280px;
			height: 100%;
			color: var(--color-white);
			@include mixins.rounded;

			&:hover {
				.ThumbnailContainer {
					&:after {
						background-color: rgba(0, 0, 0, 0);
					}
				}
			}

			&.Short {
				min-width: 190px;
			}

			.ThumbnailContainer {
				&:after {
					position: absolute;
					top: 0;
					left: 0;
					z-index: 1;
					transition: background-color 0.2s ease;
					background-color: rgba(0, 0, 0, 0.1);
					width: 100%;
					height: 100%;
					content: '';
				}
			}
			.Thumbnail {
				display: block;
				border-bottom: 4px solid var(--color-brand-primary);
				aspect-ratio: 16/9;
				width: 100%;
				object-fit: cover;

				&.Short {
					min-height: 372px;
				}
			}
			&:hover {
				text-decoration: none;
			}

			.TextContainer {
				padding: var(--size-gap-2x) var(--size-gap-2x);
				color: var(--color-brand-primary);
				@include mixins.desktop {
					padding: var(--size-gap-3x) var(--size-gap-3x);
				}
				.Title {
					color: var(--color-white);
					font: var(--font-title-s);
					@include mixins.max-lines(3);
				}
				.Description {
					@include mixins.max-lines(2);
					color: var(--color-white);
					font-size: var(--font-small);
					line-height: 1.5;
				}
			}
		}
	}
}
