@use '~/src/lib/mixins';

.IndexPartners {
	background-color: var(--color-offblack);
	padding: var(--size-gap-3x) var(--size-gap-2x) var(--size-gap-4x);
	width: 100%;

	.PartnersContainer {
		display: flex;
		flex-direction: column;
		align-items: center;

		h2 {
			margin: 0 0 var(--size-gap-3x);
			color: white;
			font: var(--font-headline-m);
		}

		.Logos {
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 40px;

			@media (min-width: 600px) {
				flex-direction: row;
				flex-wrap: wrap;
				justify-content: center;
				width: 500px;
			}

			@include mixins.desktop {
				flex-direction: row;
				justify-content: center;
				width: 100%;
			}

			.Adidas {
				left: 1px;
				height: 32px;
			}

			.ArmyNationalGuard {
				height: 40px;
			}

			.Gatorade {
				height: 32px;
			}

			.Invisalign {
				height: 32px;
			}

			.Snickers {
				left: 2px;
				height: 32px;
			}

			.Subway {
				left: 2px;
				height: 20px;
			}

			.TRL {
				top: -4px;
				left: 6px;
				height: 40px;
			}

			img {
				display: block;
			}
		}
	}
}
