@use '~/src/lib/mixins';

.Index {
	display: flex;
	flex-direction: column;
	background-color: black;
	// gap: var(--size-gap-3x);
	// > div:nth-child(2) {
	// margin-top: calc(-1 * var(--size-gap-3x));
	// }

	.BannerGamesContainer {
		background: linear-gradient(var(--color-black), black);
	}

	.BannerGames {
		display: grid;
		align-items: flex-start;
		gap: var(--size-gap-3x);
		padding: 32px 0;

		@include mixins.desktop {
			grid-template-columns: 2fr 1fr;
			padding: 40px 0;
		}
	}
}
