@use '~/src/lib/mixins';

.IndexEvents {
	h2 {
		margin: 0 var(--size-gap-2x);
		color: var(--color-white);
		font: var(--font-headline-m);
	}

	.MoreContainer {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: var(--size-gap-2x);
		a {
			width: 100%;
			max-width: 360px;
		}
	}
	.NoEvents {
		padding: var(--size-gap-2x);
		color: var(--color-white);
	}

	ul {
		display: flex;
		flex-direction: row;
		gap: var(--size-gap-2x);
		margin-bottom: 0;
		padding: var(--size-gap-2x);
		padding-top: 0;
		max-width: var(--app-width);
		overflow-x: auto;
		overscroll-behavior-x: contain;
		scroll-behavior: smooth;
	}
}
