@use '~/src/lib/mixins';

.IndexPartners {
	margin-top: 48px;
	background-color: var(--color-offblack);
	padding: var(--size-gap-3x) var(--size-gap-2x) var(--size-gap-4x);
	width: 100%;

	.PartnersContainer {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;

		h2 {
			margin: 0 0 var(--size-gap-3x);
			color: white;
			font: var(--font-headline-m);
		}

		.LogosContainer {
			display: flex;
			flex-direction: row;
			justify-content: space-evenly;
			vertical-align: middle;
			gap: var(--size-gap-2x);
			padding: 0 64px;
			width: 100%;

			@include mixins.desktop {
				align-items: center;
				gap: var(--size-gap-2x);
			}

			.Logos {
				display: flex;
				flex-basis: 400px;
				flex-direction: row;
				flex-wrap: wrap;
				justify-content: space-evenly;
				align-items: center;
				gap: 40px 0;
				width: 100%;

				@include mixins.desktop {
					flex-basis: unset;
				}

				a {
					display: flex;
					justify-content: center;
					width: 145px;
				}

				.Adidas {
					left: 1px;
					height: 32px;
				}

				.ArmyNationalGuard {
					height: 40px;
				}

				.Gatorade {
					height: 32px;
				}

				.Invisalign {
					height: 32px;
				}

				.Snickers {
					left: 2px;
					height: 32px;
				}

				.Subway {
					left: 2px;
					height: 20px;
				}

				.TRL {
					top: -4px;
					left: 6px;
					height: 40px;
				}

				img {
					display: block;
				}
			}
		}
	}
}
