@use '~/src/lib/mixins';

.IndexTopPerformers {
	@include mixins.bordered;
	display: grid;
	grid-template-columns: 1fr;
	gap: 24px;
	margin: 0 var(--size-gap-2x);
	padding: 24px;
	color: var(--color-white);

	@include mixins.desktop {
		margin: 0;
		min-width: 320px;
	}

	h2 {
		color: var(--color-white);
		font: var(--font-headline-s);
	}

	.PlayerContainer {
		display: flex;
		flex-direction: column;
	}

	.Divider {
		background-color: #333;
		height: 1px;
	}
}
