@use '~/src/lib/mixins';

.Index {
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: black;

	.PageSeparatorContainer {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 40px 16px;
		width: 100%;

		@include mixins.desktop {
			padding: 40px 24px;
		}

		.PageSeparator {
			background-color: var(--color-black);
			width: 100%;
			max-width: 1350px;
			height: 4px;
		}
	}

	.FeaturedPlayers {
		margin: 0 16px;
	}

	.ContentHeaderContainer {
		background: linear-gradient(var(--color-black), black);
		padding-top: 24px;
		width: 100%;

		.ContentContainer > div {
			display: flex;
			flex-direction: column;
			gap: 24px;
		}
	}

	.BannerGames {
		display: grid;
		grid-template-columns: auto;
		align-items: flex-start;
		gap: var(--size-gap-3x);

		@include mixins.desktop {
			grid-template-columns: auto auto;
		}
	}
}
