@use '~/src/lib/mixins';

.IndexGameOfTheWeek {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	align-self: stretch;
	gap: 4px;
	margin: 0 16px;
	border: 1px solid #c3fa02;
	border-radius: 16px;
	background: var(--Gradient-01, linear-gradient(180deg, #1a1a1a 78.12%, #000 100%));
	padding: 16px;

	@include mixins.desktop {
		margin: 0;
	}

	h2 {
		font-style: normal;
		font-weight: 400;
		font-size: 12px;
		line-height: 133%; /* 15.96px */

		/* Other/Eyebrow */
		font-family: 'Black Ops One';
		letter-spacing: 1.2px;
		text-align: center;
		text-transform: uppercase;
	}

	.Teams {
		display: grid;
		grid-template-columns: 1fr auto 1fr;
		align-items: center;
		gap: 32px;

		&.Complete {
			grid-template-columns: 1fr auto auto auto 1fr;
			gap: 16px;

			@include mixins.desktop {
				gap: 32px;
			}
		}

		.Team {
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 4px;

			@include mixins.desktop {
				flex-direction: row;
				gap: 16px;
			}

			a {
				text-decoration: none;
			}

			&--Away {
				justify-self: flex-end;
			}
			&--Home {
				justify-self: flex-start;
			}

			.Name {
				display: none;

				@include mixins.desktop {
					display: block;
					overflow: hidden;
					color: #fff;
					font-style: normal;
					font-weight: 700;
					font-size: 12px;
					line-height: 150%; /* 18px */

					/* Body Copy/Extra Small-Bold */
					font-family: 'Roboto Flex';
					text-overflow: ellipsis;
				}
			}

			.ShortCode {
				display: block;
				overflow: hidden;
				color: #fff;
				font-style: normal;
				font-weight: 700;
				font-size: 12px;
				line-height: 150%; /* 18px */

				/* Body Copy/Extra Small-Bold */
				font-family: 'Roboto Flex';
				text-overflow: ellipsis;

				@include mixins.desktop {
					display: none;
				}
			}

			.LogoFrame {
				display: flex;
				border: 2px solid #ccc;
				border-radius: 50%;

				.Logo {
					border-radius: 50%;
					aspect-ratio: 1;
					width: 40px;

					&.Placeholder {
						padding: 6px;
					}
				}
			}
		}

		.Score {
			overflow: hidden;
			font-style: normal;
			font-weight: 400;
			font-size: 18px;
			line-height: 133%; /* 23.94px */

			/* Other/Number-Small */
			font-family: 'Black Ops One';
			text-align: center;
			text-overflow: ellipsis;

			&.Winner {
				color: #c3fa02;
			}
			&.Loser {
				color: #878787;
			}
		}

		.GameDate {
			overflow: hidden;
			color: #fff;
			font-style: normal;
			font-weight: 600;
			font-size: 16px;
			line-height: 150%; /* 24px */

			/* Body Copy/Small-SemiBold */
			font-family: 'Roboto Flex';
			text-align: center;
			text-overflow: ellipsis;
		}
	}

	.Watch a {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		gap: 8px;

		color: #f00;
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 150%; /* 24px */

		/* Body Copy/Small-Regular */
		font-family: 'Roboto Flex';
		text-align: center;
		text-decoration: none;

		svg {
			width: 27px;
		}
	}
}
