.PhotoVideo {
	display: grid;

	img {
		display: block;
		width: 100%;
	}

	.PictureFull {
		grid-area: 1/1;
		z-index: 1;
	}

	picture {
		grid-area: 1/1;
		width: 100%;
		max-width: 100%;
	}

	.VideoPlaceholder {
		grid-area: 1/1;
		width: 100%;
	}

	video {
		grid-area: 1/1;
		width: 100%;
	}

	.PictureFull {
		grid-area: 1/1;
	}
}
