@use '~/src/lib/mixins';

.FeaturedPlayers {
	@include mixins.bordered;
	background: linear-gradient(0deg, black, var(--color-black));
	padding: 24px;

	h2 {
		margin-bottom: 12px;
		border-bottom: 2px solid var(--color-offblack);
		padding-bottom: var(--size-gap-2x);
		color: var(--color-white);
		font: var(--font-headline-s);
	}

	.Players {
		display: grid;
		grid-template-columns: 1fr;
		gap: 24px;
		padding-top: var(--size-gap);

		@include mixins.desktop {
			&.Col-3 {
				grid-template-columns: repeat(3, 1fr);
			}
			&.Col-2 {
				grid-template-columns: repeat(2, 1fr);
			}
		}
	}

	.Player {
		display: grid;
		grid-template-columns: minmax(0, 145px) 1fr;
		gap: var(--size-gap-2x);
		color: var(--color-white);

		.PictureContainer {
			.PictureFrame {
				@include mixins.bordered;
				display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: center;
				background-color: var(--color-black);

				.PhotoVideo {
					aspect-ratio: 3/4;
				}

				&.Team {
					aspect-ratio: 3/4;

					.PhotoVideo {
						aspect-ratio: unset;
						max-width: 90px;
					}
				}
			}
		}

		.InfoContainer {
			display: grid;
			grid-template-rows: auto auto 1fr auto;
			grid-template-columns: 1fr;

			.Name {
				margin-top: var(--size-gap);
				color: var(--color-brand-primary);
				font: var(--font-display-s);
			}

			.Team {
				display: block;
				margin-bottom: var(--size-gap);
				border-bottom: 1px solid var(--color-brand-primary-10);
				padding-bottom: var(--size-gap);
				color: var(--color-brand-primary-20);
				font: var(--font-title-s);
			}

			.Position {
				margin-bottom: var(--size-gap);
				font: var(--font-body-s-r);
			}

			.OffersContainer {
				font-weight: 600px;
				font-size: 18px;
				font-family: 'Roboto Flex';

				.Offers {
					display: flex;
					flex-grow: 0;
					flex-direction: row;
					gap: 8px var(--size-gap-2x);

					@include mixins.desktop {
						gap: 8px var(--size-gap-3x);
					}

					.Logo {
						max-width: 30px;
						object-fit: contain;
					}

					img {
						width: 100%;
						object-fit: contain;
					}
				}
			}
		}
	}
}
