@use '~/src/lib/mixins';

.IndexTopPerformers {
	display: grid;
	margin: 0 var(--size-gap-2x);
	padding: 24px;

	color: var(--color-white);
	@include mixins.bordered;

	@include mixins.desktop {
		margin: 0;
		min-width: 430px;
	}

	h2 {
		color: var(--color-white);
		font: var(--font-headline-s);
	}

	.PlayerContainer {
		display: flex;
		flex-direction: column;
		margin-top: 24px;
		border-top: 1px solid #333;
		padding-top: 24px;

		.Player {
			display: grid;
			grid-template-columns: auto 1fr;
			gap: 12px;
			border-bottom: 1px solid #333;
			padding: 12px 0;

			&:last-child {
				border-bottom: 0;
				padding-bottom: 0;
			}

			.Photos {
				display: grid;
				width: 88px;
				height: 88px;

				.Profile {
					display: flex;
					grid-area: 1/1;
					flex-direction: row;
					justify-content: center;
					border: 2px solid #ccc;
					border-radius: 50%;
					width: 80px;
					height: 80px;
					overflow: hidden;

					.Placeholder {
						width: 44px;
					}
				}

				.Team {
					grid-area: 1/1;
					align-self: flex-end;
					justify-self: flex-end;
					z-index: 1;
					border: 2px solid var(--color-brand-primary);
					border-radius: 50%;
					width: 40px;
					height: 40px;
					overflow: hidden;
				}
			}

			.Info {
				.Name {
					color: var(--Lime-100, #c3fa02);
					font-style: normal;
					font-weight: 400;
					font-size: 24px;
					line-height: 120%; /* 28.8px */

					/* Display/Small */
					font-family: 'Black Ops One';
				}
				.Team {
					color: var(--Lime-20, #ecfeae);
					font-style: normal;
					font-weight: 600;
					font-size: 18px;
					line-height: 133%; /* 23.94px */

					/* Title/Small-SemiBold */
					font-family: 'Roboto Flex';
				}
				.Position {
					color: var(--Gray-30, #ccc);
					font-style: normal;
					font-weight: 400;
					font-size: 16px;
					line-height: 150%; /* 24px */

					/* Body Copy/Small-Regular */
					font-family: 'Roboto Flex';
				}
			}
		}
	}
}
