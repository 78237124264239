@use '~/src/lib/mixins';

.IndexBanner {
	@include mixins.rounded;
	margin: 0 var(--size-gap-2x);
	color: var(--color-white);

	@include mixins.desktop {
		margin: 0;
	}

	img {
		border-bottom: 4px solid var(--color-brand-primary);
		aspect-ratio: 16/9;
		width: 100%;
		object-fit: cover;
	}
	.Text {
		padding: var(--size-gap-2x);
		a {
			color: var(--color-brand-primary-20);
			font: var(--font-body-s-r);
			svg {
				margin-left: var(--size-gap-2x);
				color: var(--color-brand-primary);
			}
		}
	}
}
