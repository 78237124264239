:root {
  --color-brand-primary: #c3fa02;
  --color-brand-primary-10: #f6ffd9;
  --color-brand-primary-20: #ecfeae;
  --color-brand-primary-30: #dcfd67;
  --color-brand-secondary: #46928f;
  --color-brand-link: #46928f;
  --color-brand-primary-dark: #3e5001;
  --color-offblack: #333;
  --color-black: #1a1a1a;
  --color-white: #fff;
  --color-gray: #878787;
  --color-darkgray: #1c1e21;
  --color-lightgray: #ccc;
  --color-offwhite: #fafafa;
  --color-shadow: #00000040;
  --color-inner-shadow: #0000000d;
  --color-amazon-blue: #1a98ff;
  --color-youtube-red: red;
  --font-display-xl: 64px / 1.2 "black ops one", impact;
  --font-display-l: 56px / 1.2 "black ops one", impact;
  --font-display-m: 40px / 1.2 "black ops one", impact;
  --font-display-s: 24px / 1.2 "black ops one", impact;
  --font-display-xs: 18px / 1.2 "black ops one", impact;
  --font-headline-l: 300 40px / 1.2 "roboto flex", arial;
  --font-headline-m: 300 32px / 1.3 "roboto flex", arial;
  --font-headline-s: 300 24px / 1.3 "roboto flex", arial;
  --font-headline-xs: 300 16px / 1.3 "roboto flex", arial;
  --font-title-l: 600 24px / 1.33 "roboto flex", arial;
  --font-title-s: 600 18px / 1.33 "roboto flex", arial;
  --font-body-l-sb: 600 24px / 1.5 "roboto flex", arial;
  --font-body-l-r: 400 24px / 1.5 "roboto flex", arial;
  --font-body-m-sb: 600 18px / 1.5 "roboto flex", arial;
  --font-body-m-r: 400 18px / 1.5 "roboto flex", arial;
  --font-body-s-sb: 600 16px / 1.5 "roboto flex", arial;
  --font-body-s-r: 400 16px / 1.5 "roboto flex", arial;
  --font-body-xs-b: 700 12px / 1.5 "roboto flex", arial;
  --font-body-xs-r: 400 12px / 1.5 "roboto flex", arial;
  --font-number-l: 24px / 1.33 "black ops one", impact;
  --font-number-s: 18px / 1.33 "black ops one", impact;
  --font-eyebrow: 12px / 1.33 "black ops one", impact;
  --font-button: 600 14px / 16px "roboto flex", arial;
  --size-gap: var(--gap);
  --size-gap-quarter: calc(var(--gap) / 4);
  --size-gap-half: calc(var(--gap) / 2);
  --size-gap-2x: calc(var(--gap) * 2);
  --size-gap-3x: calc(var(--gap) * 3);
  --size-gap-4x: calc(var(--gap) * 4);
  --size-gap-6x: calc(var(--gap) * 6);
  --size-row-gap: var(--row-gap);
  --size-large-mobile-width: 640px;
  --size-tablet-width: 768px;
  --size-desktop-width: 990px;
  --size-content-width: calc(1366px - var(--size-gap) * 2);
}

.IndexBanner {
  margin: 0 var(--size-gap-2x);
  color: var(--color-white);
  border-radius: 16px;
  overflow: hidden;
}

@media (width >= 990px) {
  .IndexBanner {
    margin: 0;
  }
}

.IndexBanner img {
  border-bottom: 4px solid var(--color-brand-primary);
  aspect-ratio: 16 / 9;
  object-fit: cover;
  width: 100%;
}

.IndexBanner .Text {
  padding: var(--size-gap-2x);
}

.IndexBanner .Text a {
  color: var(--color-brand-primary-20);
  font: var(--font-body-s-r);
}

.IndexBanner .Text a svg {
  margin-left: var(--size-gap-2x);
  color: var(--color-brand-primary);
}

:root {
  --color-brand-primary: #c3fa02;
  --color-brand-primary-10: #f6ffd9;
  --color-brand-primary-20: #ecfeae;
  --color-brand-primary-30: #dcfd67;
  --color-brand-secondary: #46928f;
  --color-brand-link: #46928f;
  --color-brand-primary-dark: #3e5001;
  --color-offblack: #333;
  --color-black: #1a1a1a;
  --color-white: #fff;
  --color-gray: #878787;
  --color-darkgray: #1c1e21;
  --color-lightgray: #ccc;
  --color-offwhite: #fafafa;
  --color-shadow: #00000040;
  --color-inner-shadow: #0000000d;
  --color-amazon-blue: #1a98ff;
  --color-youtube-red: red;
  --font-display-xl: 64px / 1.2 "black ops one", impact;
  --font-display-l: 56px / 1.2 "black ops one", impact;
  --font-display-m: 40px / 1.2 "black ops one", impact;
  --font-display-s: 24px / 1.2 "black ops one", impact;
  --font-display-xs: 18px / 1.2 "black ops one", impact;
  --font-headline-l: 300 40px / 1.2 "roboto flex", arial;
  --font-headline-m: 300 32px / 1.3 "roboto flex", arial;
  --font-headline-s: 300 24px / 1.3 "roboto flex", arial;
  --font-headline-xs: 300 16px / 1.3 "roboto flex", arial;
  --font-title-l: 600 24px / 1.33 "roboto flex", arial;
  --font-title-s: 600 18px / 1.33 "roboto flex", arial;
  --font-body-l-sb: 600 24px / 1.5 "roboto flex", arial;
  --font-body-l-r: 400 24px / 1.5 "roboto flex", arial;
  --font-body-m-sb: 600 18px / 1.5 "roboto flex", arial;
  --font-body-m-r: 400 18px / 1.5 "roboto flex", arial;
  --font-body-s-sb: 600 16px / 1.5 "roboto flex", arial;
  --font-body-s-r: 400 16px / 1.5 "roboto flex", arial;
  --font-body-xs-b: 700 12px / 1.5 "roboto flex", arial;
  --font-body-xs-r: 400 12px / 1.5 "roboto flex", arial;
  --font-number-l: 24px / 1.33 "black ops one", impact;
  --font-number-s: 18px / 1.33 "black ops one", impact;
  --font-eyebrow: 12px / 1.33 "black ops one", impact;
  --font-button: 600 14px / 16px "roboto flex", arial;
  --size-gap: var(--gap);
  --size-gap-quarter: calc(var(--gap) / 4);
  --size-gap-half: calc(var(--gap) / 2);
  --size-gap-2x: calc(var(--gap) * 2);
  --size-gap-3x: calc(var(--gap) * 3);
  --size-gap-4x: calc(var(--gap) * 4);
  --size-gap-6x: calc(var(--gap) * 6);
  --size-row-gap: var(--row-gap);
  --size-large-mobile-width: 640px;
  --size-tablet-width: 768px;
  --size-desktop-width: 990px;
  --size-content-width: calc(1366px - var(--size-gap) * 2);
}

.IndexEvents {
  border-top: 4px solid var(--color-black);
  padding: 32px 0;
}

@media (width >= 990px) {
  .IndexEvents {
    padding: 40px 0;
  }
}

.IndexEvents h2 {
  margin: 0 var(--size-gap-2x);
  color: var(--color-white);
  font: var(--font-headline-m);
}

.IndexEvents .MoreContainer {
  padding: var(--size-gap-2x);
  flex-direction: column;
  align-items: center;
  display: flex;
}

.IndexEvents .MoreContainer a {
  width: 100%;
  max-width: 360px;
}

.IndexEvents .NoEvents {
  padding: var(--size-gap-2x);
  color: var(--color-white);
}

.IndexEvents ul {
  gap: var(--size-gap-2x);
  padding: var(--size-gap-2x);
  max-width: var(--app-width);
  overscroll-behavior-x: contain;
  scroll-behavior: smooth;
  flex-direction: row;
  margin-bottom: 0;
  padding-top: 0;
  display: flex;
  overflow-x: auto;
}

:root {
  --color-brand-primary: #c3fa02;
  --color-brand-primary-10: #f6ffd9;
  --color-brand-primary-20: #ecfeae;
  --color-brand-primary-30: #dcfd67;
  --color-brand-secondary: #46928f;
  --color-brand-link: #46928f;
  --color-brand-primary-dark: #3e5001;
  --color-offblack: #333;
  --color-black: #1a1a1a;
  --color-white: #fff;
  --color-gray: #878787;
  --color-darkgray: #1c1e21;
  --color-lightgray: #ccc;
  --color-offwhite: #fafafa;
  --color-shadow: #00000040;
  --color-inner-shadow: #0000000d;
  --color-amazon-blue: #1a98ff;
  --color-youtube-red: red;
  --font-display-xl: 64px / 1.2 "black ops one", impact;
  --font-display-l: 56px / 1.2 "black ops one", impact;
  --font-display-m: 40px / 1.2 "black ops one", impact;
  --font-display-s: 24px / 1.2 "black ops one", impact;
  --font-display-xs: 18px / 1.2 "black ops one", impact;
  --font-headline-l: 300 40px / 1.2 "roboto flex", arial;
  --font-headline-m: 300 32px / 1.3 "roboto flex", arial;
  --font-headline-s: 300 24px / 1.3 "roboto flex", arial;
  --font-headline-xs: 300 16px / 1.3 "roboto flex", arial;
  --font-title-l: 600 24px / 1.33 "roboto flex", arial;
  --font-title-s: 600 18px / 1.33 "roboto flex", arial;
  --font-body-l-sb: 600 24px / 1.5 "roboto flex", arial;
  --font-body-l-r: 400 24px / 1.5 "roboto flex", arial;
  --font-body-m-sb: 600 18px / 1.5 "roboto flex", arial;
  --font-body-m-r: 400 18px / 1.5 "roboto flex", arial;
  --font-body-s-sb: 600 16px / 1.5 "roboto flex", arial;
  --font-body-s-r: 400 16px / 1.5 "roboto flex", arial;
  --font-body-xs-b: 700 12px / 1.5 "roboto flex", arial;
  --font-body-xs-r: 400 12px / 1.5 "roboto flex", arial;
  --font-number-l: 24px / 1.33 "black ops one", impact;
  --font-number-s: 18px / 1.33 "black ops one", impact;
  --font-eyebrow: 12px / 1.33 "black ops one", impact;
  --font-button: 600 14px / 16px "roboto flex", arial;
  --size-gap: var(--gap);
  --size-gap-quarter: calc(var(--gap) / 4);
  --size-gap-half: calc(var(--gap) / 2);
  --size-gap-2x: calc(var(--gap) * 2);
  --size-gap-3x: calc(var(--gap) * 3);
  --size-gap-4x: calc(var(--gap) * 4);
  --size-gap-6x: calc(var(--gap) * 6);
  --size-row-gap: var(--row-gap);
  --size-large-mobile-width: 640px;
  --size-tablet-width: 768px;
  --size-desktop-width: 990px;
  --size-content-width: calc(1366px - var(--size-gap) * 2);
}

.IndexPartners {
  background-color: var(--color-offblack);
  padding: var(--size-gap-3x) var(--size-gap-2x) var(--size-gap-4x);
  width: 100%;
}

.IndexPartners .PartnersContainer {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.IndexPartners .PartnersContainer h2 {
  margin: 0 0 var(--size-gap-3x);
  color: #fff;
  font: var(--font-headline-m);
}

.IndexPartners .PartnersContainer .Logos {
  flex-direction: column;
  align-items: center;
  gap: 40px;
  display: flex;
}

@media (width >= 600px) {
  .IndexPartners .PartnersContainer .Logos {
    flex-flow: wrap;
    justify-content: center;
    width: 500px;
  }
}

@media (width >= 990px) {
  .IndexPartners .PartnersContainer .Logos {
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
}

.IndexPartners .PartnersContainer .Logos .Adidas {
  height: 32px;
  left: 1px;
}

.IndexPartners .PartnersContainer .Logos .ArmyNationalGuard {
  height: 40px;
}

.IndexPartners .PartnersContainer .Logos .Gatorade, .IndexPartners .PartnersContainer .Logos .Invisalign {
  height: 32px;
}

.IndexPartners .PartnersContainer .Logos .Snickers {
  height: 32px;
  left: 2px;
}

.IndexPartners .PartnersContainer .Logos .Subway {
  height: 20px;
  left: 2px;
}

.IndexPartners .PartnersContainer .Logos .TRL {
  height: 40px;
  top: -4px;
  left: 6px;
}

.IndexPartners .PartnersContainer .Logos img {
  display: block;
}

:root {
  --color-brand-primary: #c3fa02;
  --color-brand-primary-10: #f6ffd9;
  --color-brand-primary-20: #ecfeae;
  --color-brand-primary-30: #dcfd67;
  --color-brand-secondary: #46928f;
  --color-brand-link: #46928f;
  --color-brand-primary-dark: #3e5001;
  --color-offblack: #333;
  --color-black: #1a1a1a;
  --color-white: #fff;
  --color-gray: #878787;
  --color-darkgray: #1c1e21;
  --color-lightgray: #ccc;
  --color-offwhite: #fafafa;
  --color-shadow: #00000040;
  --color-inner-shadow: #0000000d;
  --color-amazon-blue: #1a98ff;
  --color-youtube-red: red;
  --font-display-xl: 64px / 1.2 "black ops one", impact;
  --font-display-l: 56px / 1.2 "black ops one", impact;
  --font-display-m: 40px / 1.2 "black ops one", impact;
  --font-display-s: 24px / 1.2 "black ops one", impact;
  --font-display-xs: 18px / 1.2 "black ops one", impact;
  --font-headline-l: 300 40px / 1.2 "roboto flex", arial;
  --font-headline-m: 300 32px / 1.3 "roboto flex", arial;
  --font-headline-s: 300 24px / 1.3 "roboto flex", arial;
  --font-headline-xs: 300 16px / 1.3 "roboto flex", arial;
  --font-title-l: 600 24px / 1.33 "roboto flex", arial;
  --font-title-s: 600 18px / 1.33 "roboto flex", arial;
  --font-body-l-sb: 600 24px / 1.5 "roboto flex", arial;
  --font-body-l-r: 400 24px / 1.5 "roboto flex", arial;
  --font-body-m-sb: 600 18px / 1.5 "roboto flex", arial;
  --font-body-m-r: 400 18px / 1.5 "roboto flex", arial;
  --font-body-s-sb: 600 16px / 1.5 "roboto flex", arial;
  --font-body-s-r: 400 16px / 1.5 "roboto flex", arial;
  --font-body-xs-b: 700 12px / 1.5 "roboto flex", arial;
  --font-body-xs-r: 400 12px / 1.5 "roboto flex", arial;
  --font-number-l: 24px / 1.33 "black ops one", impact;
  --font-number-s: 18px / 1.33 "black ops one", impact;
  --font-eyebrow: 12px / 1.33 "black ops one", impact;
  --font-button: 600 14px / 16px "roboto flex", arial;
  --size-gap: var(--gap);
  --size-gap-quarter: calc(var(--gap) / 4);
  --size-gap-half: calc(var(--gap) / 2);
  --size-gap-2x: calc(var(--gap) * 2);
  --size-gap-3x: calc(var(--gap) * 3);
  --size-gap-4x: calc(var(--gap) * 4);
  --size-gap-6x: calc(var(--gap) * 6);
  --size-row-gap: var(--row-gap);
  --size-large-mobile-width: 640px;
  --size-tablet-width: 768px;
  --size-desktop-width: 990px;
  --size-content-width: calc(1366px - var(--size-gap) * 2);
}

.IndexVideos {
  border-top: 4px solid var(--color-black);
  padding: 32px 0;
}

@media (width >= 990px) {
  .IndexVideos {
    padding: 40px 0;
  }
}

.IndexVideos .Heading {
  margin-bottom: var(--size-gap-3x);
  padding: 0 var(--size-gap-2x);
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.IndexVideos .Heading h2 {
  color: var(--color-white);
  font: var(--font-headline-m);
}

.IndexVideos .Heading .More {
  color: var(--color-brand-primary-20);
  font: var(--font-body-s-r);
  display: block;
}

.IndexVideos .Heading .More svg {
  margin-left: var(--size-gap-2x);
  color: var(--color-brand-primary);
}

.IndexVideos .Heading .More.SoldOut {
  color: var(--color-gray);
  text-decoration: line-through;
}

.IndexVideos .VideosList {
  align-items: stretch;
  gap: var(--size-gap-2x);
  padding: 0 var(--size-gap-2x);
  max-width: var(--app-width);
  overscroll-behavior-x: contain;
  scroll-behavior: smooth;
  flex-direction: row;
  margin: 0;
  display: flex;
  overflow-x: auto;
}

.IndexVideos .VideosList .Video {
  gap: var(--size-gap-quarter);
  background-color: var(--color-black);
  color: var(--color-white);
  border-radius: 16px;
  flex-direction: column;
  flex-shrink: 1;
  min-width: 250px;
  max-width: 280px;
  height: 100%;
  display: flex;
  overflow: hidden;
}

.IndexVideos .VideosList .Video:hover .ThumbnailContainer:after {
  background-color: #0000;
}

.IndexVideos .VideosList .Video.Short {
  min-width: 190px;
}

.IndexVideos .VideosList .Video .ThumbnailContainer:after {
  z-index: 1;
  content: "";
  background-color: #0000001a;
  width: 100%;
  height: 100%;
  transition: background-color .2s;
  position: absolute;
  top: 0;
  left: 0;
}

.IndexVideos .VideosList .Video .Thumbnail {
  border-bottom: 4px solid var(--color-brand-primary);
  aspect-ratio: 16 / 9;
  object-fit: cover;
  width: 100%;
  display: block;
}

.IndexVideos .VideosList .Video .Thumbnail.Short {
  min-height: 372px;
}

.IndexVideos .VideosList .Video:hover {
  text-decoration: none;
}

.IndexVideos .VideosList .Video .TextContainer {
  padding: var(--size-gap-2x) var(--size-gap-2x);
  color: var(--color-brand-primary);
}

@media (width >= 990px) {
  .IndexVideos .VideosList .Video .TextContainer {
    padding: var(--size-gap-3x) var(--size-gap-3x);
  }
}

.IndexVideos .VideosList .Video .TextContainer .Title {
  color: var(--color-white);
  font: var(--font-title-s);
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.IndexVideos .VideosList .Video .TextContainer .Description {
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  color: var(--color-white);
  font-size: var(--font-small);
  -webkit-box-orient: vertical;
  line-height: 1.5;
  display: -webkit-box;
  overflow: hidden;
}

:root {
  --color-brand-primary: #c3fa02;
  --color-brand-primary-10: #f6ffd9;
  --color-brand-primary-20: #ecfeae;
  --color-brand-primary-30: #dcfd67;
  --color-brand-secondary: #46928f;
  --color-brand-link: #46928f;
  --color-brand-primary-dark: #3e5001;
  --color-offblack: #333;
  --color-black: #1a1a1a;
  --color-white: #fff;
  --color-gray: #878787;
  --color-darkgray: #1c1e21;
  --color-lightgray: #ccc;
  --color-offwhite: #fafafa;
  --color-shadow: #00000040;
  --color-inner-shadow: #0000000d;
  --color-amazon-blue: #1a98ff;
  --color-youtube-red: red;
  --font-display-xl: 64px / 1.2 "black ops one", impact;
  --font-display-l: 56px / 1.2 "black ops one", impact;
  --font-display-m: 40px / 1.2 "black ops one", impact;
  --font-display-s: 24px / 1.2 "black ops one", impact;
  --font-display-xs: 18px / 1.2 "black ops one", impact;
  --font-headline-l: 300 40px / 1.2 "roboto flex", arial;
  --font-headline-m: 300 32px / 1.3 "roboto flex", arial;
  --font-headline-s: 300 24px / 1.3 "roboto flex", arial;
  --font-headline-xs: 300 16px / 1.3 "roboto flex", arial;
  --font-title-l: 600 24px / 1.33 "roboto flex", arial;
  --font-title-s: 600 18px / 1.33 "roboto flex", arial;
  --font-body-l-sb: 600 24px / 1.5 "roboto flex", arial;
  --font-body-l-r: 400 24px / 1.5 "roboto flex", arial;
  --font-body-m-sb: 600 18px / 1.5 "roboto flex", arial;
  --font-body-m-r: 400 18px / 1.5 "roboto flex", arial;
  --font-body-s-sb: 600 16px / 1.5 "roboto flex", arial;
  --font-body-s-r: 400 16px / 1.5 "roboto flex", arial;
  --font-body-xs-b: 700 12px / 1.5 "roboto flex", arial;
  --font-body-xs-r: 400 12px / 1.5 "roboto flex", arial;
  --font-number-l: 24px / 1.33 "black ops one", impact;
  --font-number-s: 18px / 1.33 "black ops one", impact;
  --font-eyebrow: 12px / 1.33 "black ops one", impact;
  --font-button: 600 14px / 16px "roboto flex", arial;
  --size-gap: var(--gap);
  --size-gap-quarter: calc(var(--gap) / 4);
  --size-gap-half: calc(var(--gap) / 2);
  --size-gap-2x: calc(var(--gap) * 2);
  --size-gap-3x: calc(var(--gap) * 3);
  --size-gap-4x: calc(var(--gap) * 4);
  --size-gap-6x: calc(var(--gap) * 6);
  --size-row-gap: var(--row-gap);
  --size-large-mobile-width: 640px;
  --size-tablet-width: 768px;
  --size-desktop-width: 990px;
  --size-content-width: calc(1366px - var(--size-gap) * 2);
}

.IndexTopPerformers {
  margin: 0 var(--size-gap-2x);
  color: var(--color-white);
  border: 1px solid var(--color-brand-primary);
  border-radius: 16px;
  padding: 24px;
  display: grid;
  overflow: hidden;
}

@media (width >= 990px) {
  .IndexTopPerformers {
    min-width: 430px;
    margin: 0;
  }
}

.IndexTopPerformers h2 {
  color: var(--color-white);
  font: var(--font-headline-s);
}

.IndexTopPerformers .PlayerContainer {
  border-top: 1px solid #333;
  flex-direction: column;
  margin-top: 24px;
  padding-top: 24px;
  display: flex;
}

.IndexTopPerformers .PlayerContainer .Player {
  border-bottom: 1px solid #333;
  grid-template-columns: auto 1fr;
  gap: 12px;
  padding: 12px 0;
  display: grid;
}

.IndexTopPerformers .PlayerContainer .Player:last-child {
  border-bottom: 0;
  padding-bottom: 0;
}

.IndexTopPerformers .PlayerContainer .Player .Photos {
  width: 88px;
  height: 88px;
  display: grid;
}

.IndexTopPerformers .PlayerContainer .Player .Photos .Profile {
  border: 2px solid #ccc;
  border-radius: 50%;
  flex-direction: row;
  grid-area: 1 / 1;
  justify-content: center;
  width: 80px;
  height: 80px;
  display: flex;
  overflow: hidden;
}

.IndexTopPerformers .PlayerContainer .Player .Photos .Profile .Placeholder {
  width: 44px;
}

.IndexTopPerformers .PlayerContainer .Player .Photos .Team {
  z-index: 1;
  border: 2px solid var(--color-brand-primary);
  border-radius: 50%;
  grid-area: 1 / 1;
  place-self: flex-end;
  width: 40px;
  height: 40px;
  overflow: hidden;
}

.IndexTopPerformers .PlayerContainer .Player .Info .Name {
  color: var(--Lime-100, #c3fa02);
  font-family: Black Ops One;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

.IndexTopPerformers .PlayerContainer .Player .Info .Team {
  color: var(--Lime-20, #ecfeae);
  font-family: Roboto Flex;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 133%;
}

.IndexTopPerformers .PlayerContainer .Player .Info .Position {
  color: var(--Gray-30, #ccc);
  font-family: Roboto Flex;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

:root {
  --color-brand-primary: #c3fa02;
  --color-brand-primary-10: #f6ffd9;
  --color-brand-primary-20: #ecfeae;
  --color-brand-primary-30: #dcfd67;
  --color-brand-secondary: #46928f;
  --color-brand-link: #46928f;
  --color-brand-primary-dark: #3e5001;
  --color-offblack: #333;
  --color-black: #1a1a1a;
  --color-white: #fff;
  --color-gray: #878787;
  --color-darkgray: #1c1e21;
  --color-lightgray: #ccc;
  --color-offwhite: #fafafa;
  --color-shadow: #00000040;
  --color-inner-shadow: #0000000d;
  --color-amazon-blue: #1a98ff;
  --color-youtube-red: red;
  --font-display-xl: 64px / 1.2 "black ops one", impact;
  --font-display-l: 56px / 1.2 "black ops one", impact;
  --font-display-m: 40px / 1.2 "black ops one", impact;
  --font-display-s: 24px / 1.2 "black ops one", impact;
  --font-display-xs: 18px / 1.2 "black ops one", impact;
  --font-headline-l: 300 40px / 1.2 "roboto flex", arial;
  --font-headline-m: 300 32px / 1.3 "roboto flex", arial;
  --font-headline-s: 300 24px / 1.3 "roboto flex", arial;
  --font-headline-xs: 300 16px / 1.3 "roboto flex", arial;
  --font-title-l: 600 24px / 1.33 "roboto flex", arial;
  --font-title-s: 600 18px / 1.33 "roboto flex", arial;
  --font-body-l-sb: 600 24px / 1.5 "roboto flex", arial;
  --font-body-l-r: 400 24px / 1.5 "roboto flex", arial;
  --font-body-m-sb: 600 18px / 1.5 "roboto flex", arial;
  --font-body-m-r: 400 18px / 1.5 "roboto flex", arial;
  --font-body-s-sb: 600 16px / 1.5 "roboto flex", arial;
  --font-body-s-r: 400 16px / 1.5 "roboto flex", arial;
  --font-body-xs-b: 700 12px / 1.5 "roboto flex", arial;
  --font-body-xs-r: 400 12px / 1.5 "roboto flex", arial;
  --font-number-l: 24px / 1.33 "black ops one", impact;
  --font-number-s: 18px / 1.33 "black ops one", impact;
  --font-eyebrow: 12px / 1.33 "black ops one", impact;
  --font-button: 600 14px / 16px "roboto flex", arial;
  --size-gap: var(--gap);
  --size-gap-quarter: calc(var(--gap) / 4);
  --size-gap-half: calc(var(--gap) / 2);
  --size-gap-2x: calc(var(--gap) * 2);
  --size-gap-3x: calc(var(--gap) * 3);
  --size-gap-4x: calc(var(--gap) * 4);
  --size-gap-6x: calc(var(--gap) * 6);
  --size-row-gap: var(--row-gap);
  --size-large-mobile-width: 640px;
  --size-tablet-width: 768px;
  --size-desktop-width: 990px;
  --size-content-width: calc(1366px - var(--size-gap) * 2);
}

.Index {
  background-color: #000;
  flex-direction: column;
  display: flex;
}

.Index .BannerGamesContainer {
  background: linear-gradient(var(--color-black), black);
}

.Index .BannerGames {
  align-items: flex-start;
  gap: var(--size-gap-3x);
  padding: 32px 0;
  display: grid;
}

@media (width >= 990px) {
  .Index .BannerGames {
    grid-template-columns: 2fr 1fr;
    padding: 40px 0;
  }
}

/*# sourceMappingURL=Index.026dc812.css.map */
